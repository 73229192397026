(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/wager-payment-flow/standard-flow/assets/javascripts/middlewares-setup.js') >= 0) return;  svs.modules.push('/components/wager-payment-flow/standard-flow/assets/javascripts/middlewares-setup.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  dialog
} = svs.components;
const {
  Confirm,
  branding,
  type,
  message
} = dialog;
const {
  getDisplayName,
  getProductClassName
} = svs.utils.products;
const {
  accountBalance,
  competition,
  spelpaus,
  paymentConfirm,
  timeLimits
} = svs.components.payment.middleware;
const {
  paymentDone,
  utils: paymentDoneUtils
} = svs.components.payment.middleware.paymentDone;
const {
  resolveCompetitionName
} = svs.components.marketplaceCompetition.helpers;
const {
  getCmsDataDocumentAsync
} = svs.components.lbUils.getCmsData;
const spelpausMW = spelpaus(interactionContext => new Promise((resolve, reject) => {
  const defaultAction = [{
    title: 'Stäng',
    callback: () => {},
    showLoader: true
  }];
  const {
    text,
    title,
    icon,
    actions = defaultAction
  } = interactionContext.data.exclusion;
  dialog.api.add(new Confirm({
    branding: svs.components.dialog.branding.SPORT,
    type: svs.components.dialog.type.DEFAULT,
    icon,
    title,
    message: [{
      type: svs.components.dialog.message.TEXT,
      text
    }],
    actions: actions.map(action => [_objectSpread(_objectSpread({}, action), {}, {
      callback: () => {
        var _action$callback;
        !action.shouldNotClose && svs.components.dialog.api.close();
        !action.shouldNotReject && reject();
        (_action$callback = action.callback) === null || _action$callback === void 0 || _action$callback.call(action);
      }
    })][0])
  }));
}));
const timeLimitsMW = timeLimits(interactionContext => new Promise((resolve, reject) => {
  const {
    messages,
    title
  } = interactionContext;
  dialog.api.add(new Confirm({
    branding: branding.SPORT,
    type: type.DEFAULT,
    icon: 'time-limit',
    title,
    message: [{
      type: message.TEXT,
      text: [messages[0], messages[1]].join('<br />')
    }],
    actions: [{
      title: 'Stäng',
      showLoader: true,
      callback() {
        dialog.api.close();
        reject(new Error('Time limit exceeded'));
      }
    }, {
      title: 'Till mina gränser',
      showLoader: true,
      callback() {
        dialog.api.close();
        resolve();
      }
    }]
  }));
}));

const accountBalanceMW = accountBalance(interactionContext => new Promise((resolve, reject) => {
  const {
    messages,
    title
  } = interactionContext;
  dialog.api.add(new Confirm({
    branding: branding.SPORT,
    type: type.DEFAULT,
    icon: 'balance',
    title,
    message: [{
      type: message.TEXT,
      text: [messages[0], messages[1]].join('<br />')
    }],
    actions: [{
      title: 'Avbryt',
      showLoader: true,
      callback() {
        dialog.api.close();
        reject();
      }
    }, {
      title: 'Sätt in',
      showLoader: true,
      callback() {
        dialog.api.close();
        resolve();
      }
    }]
  }));
}));

const competitionMW = competition(async interactionContext => {
  var _result$;
  const {
    bet,
    competition,
    isParticipating,
    wager
  } = interactionContext;
  const {
    details: {
      startTime
    },
    signupStartTime
  } = competition.getData();
  const {
    policyPersonalData
  } = svs.cmsData.data.rulesAndTerms;
  const {
    result
  } = await getCmsDataDocumentAsync(policyPersonalData);
  const infoLink = (_result$ = result[0]) === null || _result$ === void 0 ? void 0 : _result$.documentUrl;
  return new Promise((resolve, reject) => {
    if (!isParticipating) {
      const startSignup = new Date(signupStartTime);
      const _startTime = new Date(startTime);
      const now = new Date();
      if (now < startSignup && now >= _startTime) {
        reject();
      }
    }
    const compName = resolveCompetitionName(competition.getData());
    const {
      productId
    } = bet;
    const moreInfoLink = "/".concat(getProductClassName(productId), "/tavlingar/").concat(compName, "/om");
    let message = "<p>Med 64-radersspel p\xE5 ".concat(getDisplayName(productId), " kan du vara med i ").concat(compName, ".</p>");
    if (isParticipating) {
      message += "<p>L\xE4s mer <a href=\"".concat(moreInfoLink, "\" target=\"_blank\" aria-label=\"L\xE4s mer om ").concat(compName, "\">om ").concat(compName, " h\xE4r!</a></p>");
    }
    if (!isParticipating) {
      const rulesAndTermsLink = '/villkor-och-integritet';
      message += "<p>Genom att g\xE5 med i ".concat(compName, " godk\xE4nner du Regler och <a href=\"").concat(rulesAndTermsLink, "\" target=\"_blank\" aria-label=\"Villkor f\xF6r ").concat(compName, "\">Villkor f\xF6r ").concat(compName, "</a>.</p>");
      if (infoLink) {
        message += "<p>Information g\xE4llande <a href=".concat(infoLink, " target=\"_blank\" aria-label=\"Behandling av personuppgifter\">Behandling av personuppgifter</a></p>");
      }
    }
    dialog.api.add(new Confirm({
      branding: branding.SPORT,
      type: type.DEFAULT,
      icon: 'nav-games',
      title: "Vill du delta med detta spel i ".concat(compName, "?"),
      message: [{
        type: message.TEXT,
        text: message
      }],
      actions: [{
        title: 'Nej tack',
        showLoader: true,
        callback() {
          dialog.api.close();
          reject();
        }
      }, {
        title: isParticipating ? "Anv\xE4nd i ".concat(compName) : "Delta i ".concat(compName),
        showLoader: true,
        callback() {
          dialog.api.close();
          resolve({
            bet,
            competition,
            wager
          });
        }
      }]
    }));
  });
});
const paymentConfirmMw = callbackFunction => paymentConfirm(paymentContext => new Promise((resolve, reject) => {
  const {
    title,
    customMessage
  } = paymentContext;
  const dialogConfig = {
    branding: branding.SPORT,
    type: type.DEFAULT,
    icon: 'nav-games',
    title,
    message: [],
    actions: [{
      title: 'Avbryt',
      showLoader: true,
      callback() {
        dialog.api.close();
        reject();
      }
    }, {
      title: 'Betala',
      showLoader: true,
      callback(e, forms) {
        if (callbackFunction) {
          callbackFunction(forms[0].checked);
        }
        dialog.api.close();
        resolve();
      }
    }]
  };
  if (customMessage) {
    dialogConfig.message.push({
      type: message.TEXT,
      text: customMessage
    });
  }
  if (callbackFunction) {
    dialogConfig.message.push({
      type: message.CHECKBOX,
      text: 'Spara min rad efter betalning'
    });
  }
  dialog.api.add(new Confirm(dialogConfig));
}));
const paymentDoneSuccess = paymentDoneContext => new Promise(resolve => {
  const {
    title,
    message: dialogMessage,
    mybetsUrl,
    onCallback
  } = paymentDoneContext;
  dialog.api.add(new Confirm({
    branding: branding.SPORT,
    type: type.DEFAULT,
    icon: 'nav-games',
    title,
    message: [{
      type: message.TEXT,
      text: dialogMessage
    }],
    actions: [{
      title: 'Mina spel',
      callback: () => {
        onCallback(paymentDoneUtils.TRACKING_ACTIONS.MY_GAMES);
        dialog.api.close();
        location.href = mybetsUrl;
      },
      showLoader: true
    }, {
      title: 'Okej',
      callback: () => {
        onCallback(paymentDoneUtils.TRACKING_ACTIONS.OK);
        dialog.api.close();
        resolve();
      },
      showLoader: true
    }]
  }));
});
const paymentDoneFailure = paymentDoneContext => {
  const {
    clientErrors,
    clientCode
  } = paymentDoneContext;
  svs.components.wagerPaymentFlow.paymentFailure.paymentFailureDialog(clientErrors, clientCode);
};
const paymentDoneMW = paymentDone(paymentDoneSuccess, paymentDoneFailure);
setGlobal('svs.components.wagerPaymentFlow.standardFlow.middlewareSetup', {
  accountBalanceMW,
  competitionMW,
  paymentConfirmMw,
  spelpausMW,
  paymentDoneMW,
  timeLimitsMW
});

 })(window);